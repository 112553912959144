import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { FaSearch } from 'react-icons/fa'

const searchBox = ({ refine, currentRefinement, className, onFocus }) => (
  <form className={className}>
    <input
      className="SearchInput"
      type="text"
      placeholder="Buscador"
      aria-label="Buscador"
      onChange={e => refine(e.target.value)}
      value={currentRefinement}
      onFocus={onFocus}
    />
    <FaSearch size={30} />
  </form>
)

export default connectSearchBox(searchBox)